var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.sales_orders,"dense":"","search":_vm.search,"loading":_vm.loading,"items-per-page":100,"sort-by":"order_number","sort-desc":true},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('TableSummaryAlert',{attrs:{"title":'Pro-Formas',"total":_vm.orderTotals,"itemCount":_vm.sales_orders.length}})]},proxy:true},{key:"item.order_value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatAsCurrency("R", item.order_value))+" ")]}},{key:"item.order_amount_paid",fn:function(ref){
var item = ref.item;
return [(item.order_amount_paid === 0)?_c('span',[_vm._v(_vm._s(_vm.formatAsCurrency("R", item.order_amount_paid)))]):_c('a',{on:{"click":function($event){_vm.$set(_vm.paymentPreviewDialog, item.order_id, true);
          _vm.$refs[item.order_id].getCustomerPayments();}}},[_vm._v(_vm._s(_vm.formatAsCurrency("R", item.order_amount_paid)))]),_c('InvoicePaymentPreviewModal',{ref:item.order_id,attrs:{"paymentPreviewDialog":_vm.paymentPreviewDialog,"value":item,"type":'Pro-Forma'}})]}},{key:"item.order_amount_due",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatAsCurrency("R", item.order_amount_due))+" ")]}},{key:"item.order_number",fn:function(ref){
          var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.viewSalesOrder(item)}}},[_vm._v(_vm._s(item.order_number))])]}},{key:"no-data",fn:function(){return [_c('EmptyTableSlot',{attrs:{"type":'Pro-Formas'}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }