<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="sales_orders"
      dense
      :search="search"
      :loading="loading"
      :items-per-page="100"
      sort-by="order_number"
      :sort-desc="true"
    >
      <!--Filters-->
      <template v-slot:top>
        <TableSummaryAlert
          :title="'Pro-Formas'"
          :total="orderTotals"
          :itemCount="sales_orders.length"
        />
      </template>
      <!--Format Totals-->
      <template v-slot:item.order_value="{ item }">
        {{ formatAsCurrency("R", item.order_value) }}
      </template>
      <!--Format amount paid-->
      <template v-slot:item.order_amount_paid="{ item }">
        <span v-if="item.order_amount_paid === 0">{{
          formatAsCurrency("R", item.order_amount_paid)
        }}</span>
        <a
          v-else
          @click="
            $set(paymentPreviewDialog, item.order_id, true);
            $refs[item.order_id].getCustomerPayments();
          "
          >{{ formatAsCurrency("R", item.order_amount_paid) }}</a
        >
        <!--View payments-->
        <InvoicePaymentPreviewModal
          :ref="item.order_id"
          :paymentPreviewDialog="paymentPreviewDialog"
          :value="item"
          :type="'Pro-Forma'"
        />
      </template>
      <!--Format amount due-->
      <template v-slot:item.order_amount_due="{ item }">
        {{ formatAsCurrency("R", item.order_amount_due) }}
      </template>
      <!--Click to view sales order-->
      <template v-slot:item.order_number="{ item }">
        <a @click="viewSalesOrder(item)">{{ item.order_number }}</a>
      </template>
      <!--Empty-->
      <template v-slot:no-data>
        <EmptyTableSlot :type="'Pro-Formas'" />
      </template>
    </v-data-table>
  </div>
</template>
<script>
import db from "../../../../components/firebaseInit";
import { formatAsCurrency } from "../../../../composables/external";
export default {
  name: "CustomerProFormasTable",
  props: ["customer_id"],
  components: {
    TableSummaryAlert: () => import("../Alerts/TableSummaryAlert.vue"),
    InvoicePaymentPreviewModal: () =>
      import(
        "../../../Accounting/components/Modals/InvoicePaymentPreviewModal.vue"
      ),
    EmptyTableSlot: () => import("../Tables/EmptyTableSlot.vue"),
  },
  data() {
    return {
      search: "",
      loading: false,
      paymentPreviewDialog: {},
      sales_orders: [],
      headers: [
        { text: "Order #", value: "order_number", align: "start" },
        { text: "Date", value: "order_date" },
        { text: "Amount Paid", value: "order_amount_paid", align: "right" },
        { text: "Amount Still Due", value: "order_amount_due", align: "right" },
        { text: "Total", value: "order_value", align: "right" },
      ],
    };
  },
  computed: {
    orderTotals() {
      return this.sales_orders.reduce(
        (total, item) => total + item.order_value,
        0
      );
    },
  },
  created() {
    this.getSalesOrders();
  },
  methods: {
    formatAsCurrency,
    getSalesOrders() {
      this.loading = true;
      const docRef = db
        .collection("sales_orders")
        .where("customer.customer_id", "==", this.customer_id)
        .where("order_status", "in", ["Confirmed", "Completed"])
        .where("invoice_id", "==", null);

      docRef
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            this.sales_orders.push(data);
          });
          this.loading = false;
        })
        .catch((error) => error);
    },
    //
    viewSalesOrder(item) {
      const routeData = this.$router.resolve({
        path: `/view-order/${item.order_id}`,
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>